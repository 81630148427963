import React, { useRef } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import useStyles from "../css/login";
import Container from "@material-ui/core/Container";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import BackButton from "../components/backButton";
import AuthService from "../services/auth-service";
import logo from "../FoodSVG.svg";
import ErrorMessage from "../components/errorMessage";
import ChooseLocale from "../components/chooseLocale";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import _config from "../config/config";
export default function SignIn() {
  const classes = useStyles();
  const form = useRef();
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(undefined);
  const { locale, setLocale } = React.useContext(LocaleContext);
  const handleChange = (prop) => (event) => {
    //setValues({ ...values, [prop]: event.target.value });
    switch (prop) {
      case "email":
        setEmail(event.target.value.toLowerCase());
        break;
      case "password":
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    const res = await AuthService.login({ email, password });

    if (res.data.success) {
      let _locale = res.data.result.user.locale;
      if (_locale && _config.changeLocale) {
        setLocale("en");
      } else setLocale("en");
      history.push("/home");
    } else {
      if (
        res.data.errorMessage.hasOwnProperty("validation") &&
        !res.data.errorMessage.validation
      ) {
        await AuthService.newValidationCode({ email });
        history.push("/verify");
      }
      setError(res.data.errorMessage.message);
    }
  };

  return (
    <AppLayout style={{ overflowY: "scroll" }}>
      <BackButton history={history} location={"/home/"} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} style={{width:"15em"}} alt="avatar" />
          <ErrorMessage
            errorMessage={{ message: error }}
            height={"1.5em"}
          ></ErrorMessage>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleRegister}
            ref={form}
          >
            <input
              className={"nxt input-round"}
              placeholder={Languages[locale].global.email}
              value={email}
              onChange={handleChange("email")}
              autoComplete="email"
              type="email"
            />
            <input
              className={"nxt input-round"}
              placeholder={Languages[locale].global.password}
              value={password}
              onChange={handleChange("password")}
              type="password"
            />
            <Link
              href="/forgot/password"
              variant="body2"
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#141A30",
              }}
            >
              {Languages[locale].global.forgotPassword}
            </Link>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{
                borderRadius: "50px",
                backgroundColor: "",
                padding: "12px 12px",
              }}
            >
              Login
            </Button>
            <Link
              href="/signup"
              variant="body2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              
              <span style={{ color: "" }}> &nbsp;Sign up</span>
            </Link>
          </form>
        </div>
        <Box style={{ marginTop: "18vh" }}>
          
          <p
            style={{
              textAlign: "center",
              lineHeight: "14px",
              fontSize: "10px",
              fontWeight: "normal",
              fontStyle: "normal",
              marginTop:"20px"
            }}
          >
            {"Copyright © "}
            <Link
              color="inherit"
              href="/reckon.ai"
              style={{ color: "#141A30" }}
            >
              {process.env.main || "Reckon.ai"}
            </Link>{" "}
            {new Date().getFullYear()}
            {""}
          </p>
        </Box>
      </Container>
    </AppLayout>
  );
}
