import React from "react";
import AppLayout from "./layout";
import "../styles/process.css";
import LocaleContext from "../context/LocaleContext";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "95%",
  },
  btnFull: {
    width: "100%",
    marginTop: "2vh",
    color: "white",
  },
}));

export default function CloseDoor() {
  const history = useHistory();
  const classes = useStyles();
  const { locale } = React.useContext(LocaleContext);
  const [expired, setExpired] = React.useState("");

  const changeToHome = () => {
    history.push("/home");
  };
  

  return (
    <AppLayout
      title={""}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="payment-loader" style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection:"column"
      }}>
      <img
          src={process.env.PUBLIC_URL + "/close.png"}
          alt="Open Cabinet"
          style={{ width: "70%", margin: "auto" }}
        />
        <div
          className="loader-text"
        >
          <p style={{color:"#141A30",fontWeight:"500"}}>Processing Payment. It will soon be available in your Purchases page.</p>
          <Button
        variant="contained"
        color="primary"
        onClick={changeToHome}
        className={clsx(classes.btnFull)}
        endIcon={<ExitToAppIcon />}
        style={{
          borderRadius: "50px",
          backgroundColor: "",
          padding: "12px 12px",
        }}
      >
        Close
      </Button>
        </div>
      </div>
    </AppLayout>
  );
}
