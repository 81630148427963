import React from "react";
import "./styles/common.css";
import BuyByeService from "./services/buybye-service";

import LocaleContext from "./context/LocaleContext";

export default function App(props) {
  const language = BuyByeService.getToken("locale");
  const [locale, setLocale] = React.useState("en");

  console.log("estou no app");

  const value = React.useMemo(
    () => ({
      locale,
      setLocale,
    }),
    [locale]
  );
  return (
    <LocaleContext.Provider value={value}>
      {props.children}
    </LocaleContext.Provider>
  );
}
